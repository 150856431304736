import React, { useState, useEffect, useRef } from 'react';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import './App.css';
import Colours from './utilities/colours.js';

import question_data from './assets/question_data.json';
import personalities from './assets/personalities.json';
import socials from './assets/social_buttons.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faShareNodes, faEnvelope, faDownload, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'



library.add(fab, fas, faFacebook, faLinkedin, faInstagram, faTwitter, faEnvelope, faDownload)



export default function App() {
  const data = question_data;

  const [questionInc, setQuestionInc] = useState(0);
  const [questionNo, setQuestionNo] = useState(data[questionInc].question_no);

  const [optionArr, setOptionArr] = useState([]);
  const [answerArr, updateAnswerArr] = useState([]);

  const [quizComplete, setQuizComplete] = useState(false);
  const [heartTypeIndex, setHeartTypeIndex] = useState("");
  const [heartType, setHeartType] = useState("");

  const [shareCard, setShareCard] = useState(false);

  const fetchOptions = () => {
    setOptionArr([data[questionInc].answer_a, data[questionInc].answer_b, data[questionInc].answer_c, data[questionInc].answer_d]);
  }

  const compileAnswers = (answer, allAnswered) => {
    if (!allAnswered) {
      for (let i = 0; i < data.length - 1; i++) {
        document.getElementById(`option-button-${i}`).style.backgroundColor = '#FFFFFF';
        document.getElementById(`answer-text-${i}`).style.color = '#000000';
      }
    }

    updateAnswerArr(
      [
        ...answerArr,
        String.fromCharCode(97 + answer)
      ]
    );
    answerArr.push(String.fromCharCode(97 + answer));

    if (allAnswered) {
      const counter = {};
      setQuizComplete(true);
      answerArr.forEach(item => {
        if (counter[item]) {
          counter[item] += 1;
        }
        else {
          counter[item] = 1;
        }
      });
      
      calculateResult(counter);
    }
  }

  const resetQuiz = () => {
    setQuestionInc(0);
    setQuestionNo(1);
    setOptionArr([]);
    updateAnswerArr([]);
    setQuizComplete(false);
    setHeartTypeIndex("");
    setHeartType("");
    setShareCard(false);

    document.getElementById('quiz-container').style.backgroundImage = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "url('quiz_bg_placeholder.jpeg')" : "url(https://world-heart-federation.org/world-heart-day/wp-content/themes/world-heart-federation-child__WHD/assets/js/quiz-build/quiz_bg_placeholder.jpeg)"
  }

  const calculateResult = (resultsObject) => {
    let orderedResults = [resultsObject.a, resultsObject.b, resultsObject.c, resultsObject.d];
    let highestTotal, letter;

    for (let i = 0; i < orderedResults.length; i++) {
      if (orderedResults[i] === undefined) {
        orderedResults[i] = 0;
      }

      if (i === 0) {
        orderedResults[i] = (orderedResults[i] * 1.3).toFixed(1);
      }
      else if (i === 1) {
        orderedResults[i] = (orderedResults[i] * 1.2).toFixed(1);
      }
      else if (i === 2) {
        orderedResults[i] = (orderedResults[i] * 1.1).toFixed(1);
      }
      else if (i === 3) {
        orderedResults[i] = (orderedResults[i] * 1.0).toFixed(1);
      }

      highestTotal = Math.max(...orderedResults);
    }

    switch(orderedResults.indexOf(highestTotal.toFixed(1).toString())) {
      case 0:
        letter = "A";
        break;

      case 1:
        letter = "B";
        break;

      case 2:
        letter = "C";
        break;

      case 3:
        letter = "D";
        break;

      default:
        letter = "0";
    }
    
    assignPersonality(letter);
  }

  const assignPersonality = (letter) => {
    if (letter !== "0") {
      for (let i = 0; i < personalities.length; i++) {
        if (personalities[i].answer_type === letter) {
          setHeartType(personalities[i].personality)
          setHeartTypeIndex(i);
        }
      }
    }
    else {
      setHeartType("heart...")
    }
  }

  const nextQuestion = () => {
    setQuestionNo(questionInc + 1);
    fetchOptions();
  }

  const handleShare = () => {
    setShareCard(true);
  }

  const setBackgroundImage = () => {
    let url;
    if (quizComplete) {
      url = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? `url(${personalities[heartTypeIndex].background})` : `url(https://world-heart-federation.org/world-heart-day/wp-content/themes/world-heart-federation-child__WHD/assets/js/quiz-build/${personalities[heartTypeIndex].background})`
      document.getElementById("quiz-container").style.backgroundImage = url;
    }
  }

  const copyText = () => {
    let copiedText = ((document.getElementById("copy-social-text").innerHTML).split('<'))[0] + " " + document.getElementById("copy-social-link")

    navigator.clipboard.writeText(copiedText).then(
      () => {
        console.log("copied")
        document.getElementById("copy-confirm").style.visibility = 'visible';
        setTimeout(function() {document.getElementById("copy-confirm").style.visibility = 'hidden'}, 3000);
      },
      () => {
        console.log("failed to copy text")
      }
    );
  }

  const handlePreviousQuestion = () => {
    setQuestionInc(questionInc - 1);
    let previousAnswerCode = answerArr.pop();
    let previousAnswer = previousAnswerCode.charCodeAt() - 97;

    for (let i = 0; i < data.length - 1; i++) {
      document.getElementById(`option-button-${i}`).style.backgroundColor = '#FFFFFF';
      document.getElementById(`answer-text-${i}`).style.color = '#000000';
    }

    document.getElementById(`option-button-${previousAnswer}`).style.backgroundColor = Colours.WHD_RED;
    document.getElementById(`answer-text-${previousAnswer}`).style.color = '#FFFFFF';
  }

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      console.log("initial render complete");
      console.log("test")
      initialRender.current = false;
      fetchOptions();
    } 
    else {
      nextQuestion();
    }
  }, [questionInc]);

  useEffect(() => {
    if (!initialRender.current)
      setBackgroundImage();
  }, [heartTypeIndex]);

  const setProgress = (number) => {
    if (questionNo >= number)
      return "col-12 h-100 rounded-pill bg-white opacity-100";
    else
      return "col-12 h-100 rounded-pill bg-white opacity-50";
  }


  return (
    <div className="App">
      <section id="quiz-container" 
        className={`container-fluid ${quizComplete ? 'quiz-complete-bg' : ''}`} 
        style={{ backgroundImage: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "url('quiz_bg_placeholder.jpeg')" : "url(https://world-heart-federation.org/world-heart-day/wp-content/themes/world-heart-federation-child__WHD/assets/js/quiz-build/quiz_bg_placeholder.jpeg)" }}>
        <div className="row h-100 gradient-row" style={{ backgroundImage: quizComplete ? 'linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(0,0,0,0.5) 100%)' : "linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(0,0,0,1) 100%" }}>
          <div className="col-12 h-100 p-0">

            <div id="quiz-wrapper" className="container h-100">
              <div className="row h-100">
                <div className={`col-12 ${quizComplete ? 'h-100 d-flex align-items-center justify-content-center' : ''}`}>

                  <div className={`row progress-wrapper justify-content-between px-3 ${quizComplete ? 'd-none' : 'd-flex'}`}>
                    <div className="col-2 quiz-progress-bar px-0">
                      <div className={setProgress(1)}></div>
                    </div>
                    <div className="col-2 quiz-progress-bar px-0">
                      <div className={setProgress(2)}></div>
                    </div>
                    <div className="col-2 quiz-progress-bar px-0">
                      <div className={setProgress(3)}></div>
                    </div>
                    <div className="col-2 quiz-progress-bar px-0">
                      <div className={setProgress(4)}></div>
                    </div>
                    <div className="col-2 quiz-progress-bar px-0">
                      <div className={setProgress(5)}></div>
                    </div>
                  </div>

                  
                  {shareCard
                    ?
                    <div className="card share-card w-100 border-0">
                      <div className="card-body quiz-card-body">
                        <div className="container py-3">
                          <div className="row">
                            <div className="col-12">
                              <div className="row justify-content-center">

                                <div className="col-10">

                                  <h3 className="share-screen-title text-uppercase mb-3">Share your heart personality</h3>
                                  <p className="share-text pb-0">Download your heart personality graphic:</p>
                                  <a id="download-btn" href={(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? personalities[heartTypeIndex].image : `https://world-heart-federation.org/world-heart-day/wp-content/themes/world-heart-federation-child__WHD/assets/js/quiz-build/${personalities[heartTypeIndex].image}`} target="_blank" rel="noreferrer" download className="share-button text-white text-uppercase border-0 rounded-1 fw-bold text-decoration-none mb-2">Download GIF {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? <FontAwesomeIcon icon={["fas", "download"]} /> : <i class="fal fa-arrow-to-bottom pl-1"></i>}</a>

                                  <p className="share-text mt-4 mb-1">Copy the text below and share it on social media!</p>

                                  <div id="copy-social-text-wrapper" className="rounded-1 mb-3">
                                    <span><p id="copy-social-text">{`I have a ${(personalities[heartTypeIndex].personality).toLowerCase()}!`} On #WorldHeartDay, 29 September, find out your heart personality, take the quiz! #UseHeart <a href="https://world-heart-federation.org/world-heart-day/get-involved/whats-your-heart-personality-quiz/" target="_blank" rel="noreferrer" id="copy-social-link" className="text-decoration-none pe-none" style={{ color: Colours.WHD_RED }}>https://world-heart-federation.org/world-heart-day/get-involved/whats-your-heart-personality-quiz/</a></p></span>
                                  </div>

                                  <div className="d-flex align-items-center">
                                    <button id="copy-btn" onClick={copyText} className="share-button text-white text-uppercase border-0 rounded-1 fw-bold mb-4">Copy post</button>
                                    <p id="copy-confirm" class="p-3" style={{ visibility: 'hidden' }}>Copied!</p>
                                  </div> 

                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div className="col-10 d-flex flex-wrap">
                                  {socials.map((platform, i) => (
                                    <a href={platform.link} target="_blank" rel="noreferrer" className="text-uppercase text-center border-0 text-white text-decoration-none">
                                      <div key={i} className="share-button text-white rounded-1 me-2 me-lg-3 mb-2 d-flex justify-content-center d-flex align-items-center " style={{ backgroundColor: platform.colour }}>
                                        <FontAwesomeIcon icon={[platform.icon_type, platform.icon]} />
                                        <p className="platform-text m-0 text-white fw-bold">{platform.platform}</p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div className="col-10 d-flex flex-wrap">
                                  <button id="take-again-2" className="share-button text-white text-uppercase border-0 rounded-1 fw-bold mt-3" onClick={resetQuiz}>{(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? <FontAwesomeIcon className="back-button" icon={faArrowLeftLong} /> : <i className="fal fa-long-arrow-left back-button" style={{ fontSize: 18 }}></i>}Take the quiz again</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className={`card result-card border-0 ${quizComplete ? 'share-card' : 'quiz-card'}`}>
                      <div className="card-header quiz-card-header" style={{ backgroundColor: Colours.WHD_RED }}>
                        <h3 id="question-text" className="text-center text-uppercase text-white m-0">{quizComplete ? `You have a ${personalities[heartTypeIndex].personality}` : data[questionInc].question_text}</h3>
                      </div>

                      {heartType !== "heart..."
                      ?
                      <div className="card-body quiz-card-body px-3 px-lg-5">
                        <div className="container py-3">
                          <div className="row">
                            <div className="col-12">

                              {quizComplete 
                              ? 
                              <div className="row justify-content-center">
                                <div className="col-10 col-lg-12 d-flex justify-content-center">
                                  <img className="heart-type-image w-auto" src={(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? personalities[heartTypeIndex].image : `https://world-heart-federation.org/world-heart-day/wp-content/themes/world-heart-federation-child__WHD/assets/js/quiz-build/${personalities[heartTypeIndex].image}`} alt={`${personalities[heartTypeIndex].personality} animated gif`} />
                                </div>
                                <div className="col-10 col-lg-12 d-flex justify-content-center">
                                  <p className="heart-type-caption fw-bold py-3 text-center">{personalities[heartTypeIndex].caption}</p>
                                </div>
                                <div className="col-10 col-lg-12 d-flex justify-content-center flex-column flex-lg-row">
                                  <button id="share-result" onClick={handleShare} className="share-result-button text-uppercase fw-bold border-1 rounded-1">Share your result {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? <FontAwesomeIcon icon={faShareNodes} /> : <i className="fal fa-share-alt pl-2" style={{ fontSize: 18 }}></i>}</button>
                                  <button id="take-again-1" className="result-back-button text-uppercase border-0 rounded-1 fw-bold" onClick={resetQuiz}>{(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? <FontAwesomeIcon className="back-button" icon={faArrowLeftLong} /> : <i className="fal fa-long-arrow-left back-button" style={{ fontSize: 18 }}></i>}Take the quiz again</button>

                                </div>
                              </div>
                              :
                              <div className="row justify-content-center">
                                {optionArr.map((buttonText, i) => (
                                  <div key={i} className="col-12 col-lg-6 my-2 my-lg-3">
                                    
                                    <button 
                                      onClick={() => {if (questionInc < data.length - 1) {setQuestionInc(questionInc + 1); compileAnswers(i, false)} else if (questionInc === data.length - 1) {compileAnswers(i, true)}}}
                                      id={questionInc === data.length - 1 ? "last-option-button-" + i.toString() : "option-button-" + i.toString()}
                                      className={`answer-button w-100 h-100 border-1 rounded-pill text-uppercase ${questionInc === data.length - 1 ? "last-option-button" : "" }`}
                                      style={{ borderColor: Colours.WHD_RED}}>

                                        <div className="row">
                                          <div className="col-12">
                                            <div className="row align-items-center">
                                              <div className="col-2">
                                                <p className="answer-letter bg-white border-1 rounded-circle d-flex align-items-center justify-content-center mb-0 fw-bold" style={{ color: Colours.WHD_RED, borderColor: Colours.WHD_RED }}>{String.fromCharCode(97 + i)}</p>
                                              </div>
                                              <div className="col-10 p-xl-0">
                                                <p id={"answer-text-" + i} className={`answer-text lh-sm mb-0 text-start ${questionInc === data.length - 1 ? "last-option-button" : "" }`}>{buttonText}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        
                                    </button>

                                  </div>
                                ))}
                              </div>
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="card-body quiz-card-body">
                        <div className="container py-3">
                          <div className="row">
                            <div className="col-12">

                              <p>...but something went wrong! Please try again or contact the site admin for support.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      
                      <div className={`card-footer quiz-card-footer bg-white ${quizComplete ? 'd-none' : 'd-flex justify-content-end justify-content-lg-center align-items-center'}`}>
                        <div className={`w-100 position-absolute previous-button-wrapper ${questionNo > 1 ? 'd-block' : 'd-none'}`}>
                          <button onClick={handlePreviousQuestion} className="previous-button text-white text-uppercase border-0 rounded-1 fw-bold">Previous question</button>
                        </div>
                        <p id="question-counter" className="text-center m-0"><strong>{questionNo}</strong> of <strong>{question_data.length}</strong> questions</p>
                      </div>
                    
                    </div>
                    }
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}