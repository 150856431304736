import React from 'react';
import ReactDOM from 'react-dom/client';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('whd-quiz-react'));
root.render(
  <>
  {/* <head>
    <link rel="preload" href="./public/playful_heart_background.jpg" as="image"/>
    <link rel="preload" href="./public/passionate_heart_background.jpg" as="image"/>
    <link rel="preload" href="./public/strong_heart_background.jpg" as="image"/>
    <link rel="preload" href="./public/kind_heart_background.jpg" as="image"/>
  </head> */}
  <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
